@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'big-large' {
    @media (max-width: 1395px) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (max-width: 1150px) {
      @content;
    }
  } @else if $breakpoint == 'middle' {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'mobile' {
    @media (max-width: 480px) {
      @content;
    }
  }
}
