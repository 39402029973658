@import '../../styles/variables';

.hero-section {
  text-align: center;

  .hero-title {
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
    color: $primary-color;
    margin: 0;
  }

  .hero-subtitle {
    font-size: 2rem;
    font-weight: $font-weight-bold;
    color: $dark-gray-color;
    margin-top: 0.5rem;
    padding: 0;
    margin-top: 0;
  }

  .hero-description {
    font-size: 1rem;
    color: $dark-gray-color;
    margin-top: 1rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      max-width: 88%;
    }
  }

  .hero-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      a {
        width: 100%;
        max-width: 300px;
        display: flex;
        justify-content: center;
      }

      button {
        width: 100%;
        padding: 0.75rem 0;
        font-size: 1rem;
      }
    }
  }

  .contained-button {
    background-color: $primary-color;
    color: $white-color;
    border: none;
    border-radius: 8px;

    &:hover {
      background-color: lighten($primary-color, 10%);
    }
  }

  .outlined-button {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 8px;

    &:hover {
      background-color: rgba($primary-color, 0.1);
    }
  }
}
