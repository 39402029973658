@import '../../styles/variables';
@import '../../styles/mixins';

.contact-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding-left: 20px;

  .contact-info {
    display: flex;
    flex-direction: column;
    width: 50%;

    .contact-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 30px;
      margin-left: 50px;

      .contact-title {
        font-weight: $font-weight-light;
      }

      .dot {
        width: 8px;
        height: 8px;
        background-color: $primary-color;
        border-radius: 50%;
        margin: 0 5px 0 5px;
      }
    }

    .contact-item-holder {
      display: flex;
      flex-direction: column;
      margin-left: 50px;

      .contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-bottom: 13px;

        img {
          width: 64px;
          padding-right: 15px;
        }
      }
    }

    .contact-images {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      justify-content: space-between;
      z-index: 1;
      position: relative;
      left: 60px;

      img {
        width: 30%;
        max-width: 240px;
      }
    }
  }

  .contact-form {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: $primary-color;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 70px;
    padding-right: 0;

    .contact-form-holder {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .contact-form-title {
        display: flex;
        justify-content: start;
        color: $white-color;
        font-weight: $font-weight-light;
        margin-bottom: 20px;

        img {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
      }

      .send-button {
        background-color: $white-color;
        color: $black-color !important;
        font-size: 1rem;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        width: 90%;
        max-width: 220px;

        &:hover {
          background-color: darken($white-color, 5%);
        }
      }
    }
  }

  @include respond-to('middle') {
    flex-direction: column;
    padding: 0;

    .contact-info {
      width: 92%; 
      .contact-header {
        justify-content: center;
        margin-left: 0;
      }
      .contact-item-holder {
        justify-content: center;
        margin-left: 0;
      }
      .contact-images {
        left: 0;
        top: 90px; 
      }
    }

    .contact-form {
      width: 100%; 
      padding-left: 0;
      padding: 20px;
      padding-top: 0;
    }
    .send-button {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}
