// src/styles/variables.scss

// Colors
$primary-color: #FF6F00;          // Orange for primary CTA buttons
$black-color: #000000;            // Black for main headings
$dark-gray-color: #262626;        // Dark gray for body text
$white-color: #FFFFFF;            // White for background
$background-color: $white-color;  // Background color

// Fonts
$font-primary: 'Poppins', sans-serif; // Primary font for the entire project

// Font sizes
$font-size-title: 2.5rem;           // 40px - Title size
$font-size-subtitle: 1rem;          // 16px - Subtitle and body text
$font-size-large-text: 1.125rem;    // Optional for larger body text

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;

// Spacing
$button-padding: 0.5rem 1rem;       // Padding for buttons
$border-radius: 15px;                // Rounded corners for buttons and cards
