@import '../../styles/variables';
@import '../../styles/mixins';

.events-section {
  text-align: center;
  padding: 2rem 0;
  margin-top: 50px;
  margin-bottom: 50px;

  .events-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 2rem;

    .dot {
      width: 8px;
      height: 8px;
      background-color: $primary-color;
      border-radius: 50%;
    }

    .events-title {
      font-family: $font-primary;
      font-size: 2rem;
      font-weight: $font-weight-light;
      color: $dark-gray-color;
    }
  }

  .events-container {
    display: flex;
    justify-content: space-around;
    gap: 2rem;

    .event-card {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      background: $white-color;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      width: 38%;
      max-width: 600px;
      text-align: left;

      .event-image {
        width: 150px;
        height: auto;
        border-radius: 8px;
        object-fit: cover;
      }

      .event-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .event-date-time {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: $dark-gray-color;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .event-title {
          font-size: 1.25rem;
          font-weight: 600;
          color: $dark-gray-color;
        }

        .event-description {
          font-size: 0.875rem;
          color: $dark-gray-color;
          margin-bottom: 1rem;
        }

        .event-button {
          white-space: nowrap; 
          max-width: 225px;
          width: auto; 
          padding: 10px 20px; 
          text-align: center;
          display: inline-block;
        }
      }
    }
  }

  @include respond-to('large') {
    .events-container {
      flex-direction: column;
      align-items: center;
    }

    .event-card {
      flex-direction: column;
      width: 90%;
      text-align: center;

      .event-image {
        width: 100%;
        max-width: 300px;
        margin-bottom: 1rem;
      }

      .event-info {
        align-items: center;

        .event-date-time {
          justify-content: center;
        }

        .event-title {
          margin-top: 0.5rem;
        }

        .event-description {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  @include respond-to('middle') {
    .event-card {
      width: 88% !important;
    }
  }
}
