// src/components/Navbar/NavLinks/NavLinks.scss

@import '../../../styles/variables';

@import '../../../styles/variables';

.nav-links {
    display: flex;
    list-style-type: none; 
    gap: 1.5rem; 
    margin: 0;
    padding: 0;

    a {
        text-decoration: none; 
        color: $dark-gray-color; 
        font-weight: $font-weight-regular; 
        font-family: $font-primary; 

        &:hover {
            color: $primary-color; 
        }
    }

    .home-link {
        font-weight: $font-weight-bold; 
    }
}
