// src/styles/global.scss

@import './variables';

body {
  font-family: $font-primary;
  background-color: $background-color;
  color: $dark-gray-color; 
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: $black-color;
  font-weight: $font-weight-bold;
  margin: 0;
  font-family: $font-primary;
}

p {
  font-size: $font-size-subtitle;
  font-weight: $font-weight-regular;
}

a {
  color: $primary-color;
  text-decoration: none;
  font-weight: $font-weight-regular;
}

button {
  padding: $button-padding;
  border-radius: $border-radius;
  font-weight: $font-weight-bold;
  background-color: $primary-color;
  color: $white-color;
}
.container {
    max-width: 1512px;
    margin: 0 auto;    
    padding: 0 20px;  
    display: flex;
}

