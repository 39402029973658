@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 300px;
    height: 100%;
    background-color: $white-color;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.mobile-menu--open {
        transform: translateX(0);
        opacity: 1;
    }

    &.mobile-menu--closed {
        transform: translateX(100%);
        opacity: 0;
    }

    .mobile-menu__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    .mobile-menu__close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: $black-color;
        cursor: pointer;
    }

    .mobile-menu__links {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1.5rem;

        a {
            text-decoration: none;
            color: $black-color;
            font-size: 1rem;
        }
    }
    .mobile-menu__reservation-button{
        display: block;
        margin: 0 auto;
        button {
            align-self: center;
            @include flex-center;
        }
    }

}
