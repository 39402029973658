@import '../../styles/variables';

.button {
  font-family: $font-primary;
  padding: $button-padding;
  border-radius: $border-radius;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &.primary {
    &.contained {
      background-color: $primary-color;
      color: $white-color !important; 
      border: none;

      &:hover {
        background-color: darken($primary-color, 10%); 
      }
    }

    &.outlined {
      color: $primary-color;
      border: 1px solid $primary-color;
      background-color: transparent;

      &:hover {
        background-color: rgba($primary-color, 0.1); 
      }
    }
  }

  &.secondary {
    &.contained {
      background-color: $black-color;
      color: $white-color; 
      border: none;

      &:hover {
        background-color: darken($black-color, 10%);
      }
    }

    &.outlined {
      color: $black-color;
      border: 1px solid $black-color;
      background-color: transparent;

      &:hover {
        background-color: rgba($black-color, 0.1);
      }
    }
  }

  &.white-outline {
    &.contained {
      background-color: $white-color;
      color: $primary-color; 
      border: 1px solid $primary-color;

      &:hover {
        background-color: lighten($white-color, 5%);
      }
    }

    &.outlined {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;

      &:hover {
        background-color: rgba($primary-color, 0.1); 
      }
    }
  }
  .white-black-button {
    background-color: $white-color;
    color: $black-color !important; 
    border: none;
  
    &:hover {
      background-color: lighten($white-color, 5%);
    }
  }
  
}
