@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.image-gallery-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 50px;
    overflow: hidden;

    .background-strip {
      position: absolute;
      top: 47%;
      left: 0;
      width: 100%;
      height: 177px;
      background-color: $primary-color;
      transform: translateY(-50%);
      z-index: -1;
    }

    .gallery-container {
      display: flex;
      gap: 20px;
      align-items: center;
      position: relative;
      z-index: 1;

      @include respond-to('large') {
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 1rem;

        .gallery-image {
          display: inline-block;
          width: auto; 
        }
      }
    }

    .gallery-image img {
      width: 248px;
      height: 322px;
      border-radius: 32px 0 0 0;
      border: 2px solid transparent;
      box-shadow: none;
    }
}
