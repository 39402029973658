@import '../../styles/variables';
@import '../../styles/mixins';

.footer {
    background-color: $dark-gray-color;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white-color;
    position: relative; 
    z-index: 10; 

    .footer-left {
        display: flex;
        align-items: center;
        padding-left: 20px;


        img {
            height: 40px;
            margin-right: 15px;
        }
        p{
            font-weight: $font-weight-light;
        }
    }

    .footer-center {
        display: flex;
        gap: 20px;
        padding-left: 20px;

        .footer-menu-item {
            color: $white-color;
            text-decoration: none;
            cursor: pointer;
            font-weight: $font-weight-light;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .footer-right {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-right: 20px;

        .footer-button {
            background-color: $primary-color;
            color: $white-color;
            border: none;
            padding: 10px 20px;
            border-radius: $border-radius;
            font-family: 'Poppins', sans-serif;
            font-weight: $font-weight-light;
            cursor: pointer;
            display: flex;
            align-items: center;

            .right-arrow {
                margin-left: 8px;
            }
        }
    }

    @include respond-to('large') {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 40px 0;

        .footer-left {
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 20px;

            img {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }

        .footer-center {
            flex-direction: column;
            gap: 10px;
            padding-left: 0;
            margin-bottom: 20px;
            text-align: left;

            .footer-menu-item {
                font-size: 16px;
            }
        }

        .footer-right {
            padding-right: 0;

            .footer-button {
                width: 90%;
                max-width: 300px;
                justify-content: center;
                padding: 12px 20px;
            }
        }
    }

    @include respond-to('mobile') {
        padding-bottom: 60px; 
    }
}
