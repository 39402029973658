@import '../../styles/variables';
@import '../../styles/mixins.scss';

.menu-section {
  text-align: center;
  padding: 2rem 0;

  .menu-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .dot {
      width: 8px;
      height: 8px;
      background-color: $primary-color;
      border-radius: 50%;
    }

    .menu-title {
      font-family: $font-primary;
      font-size: 2rem;
      font-weight: $font-weight-light;
      color: $dark-gray-color;
    }
  }

  .menu-description {
    font-size: 1rem;
    font-weight: $font-weight-light;
    color: $dark-gray-color;
    margin-bottom: 2rem !important;
    max-width: 400px;
    margin: 0 auto;
  }

  .menu-images {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;

    .menu-image {
      width: 100%;
      max-width: 400px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .dots-container {
    display: none; // Ne prikazujemo tacke do 1150px
    @include respond-to('big-large') {  // Tacke se prikazuju ispod 1150px
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1rem;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $dark-gray-color;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.active {
          background-color: $primary-color;
        }
      }
    }
  }

  .menu-button {
    margin-top: 1rem;
  }

  @include respond-to('big-large') {  
    .menu-images {
      overflow: hidden;
      display: flex;
      justify-content: center;

      .menu-image {
        width: 100%;
        max-width: 400px;
      }
    }
  }
}
