@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.navbar {
    width: 100%;
    background-color: $white-color;

    padding: 1rem 0;

    .container {
        max-width: 1512px;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar__links {
            margin-left: 215px;

            @include respond-to('large') {
                display: none;
            }
        }

        .navbar__buttons {
            display: flex;
            gap: 1rem;

            @include respond-to('large') {
                display: none;
            }
        }

        .navbar__hamburger {
            display: none;

            @include respond-to('large') {
                display: block;
                width: 24px;
                height: 24px;
                cursor: pointer;
                align-self: center;
                z-index: 10;
            }
        }
    }

    @include respond-to('large') {
        padding: 0.5rem 0;
    }
}
