@import '../../styles/variables';

.weekly-meal-preps-section {
  background-image: url('../../assets/background-image.png');
  background-size: cover;
  background-position: center;
  height: 613px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white-color;
  position: relative;

  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    width: 80%;
    max-width: 800px;
    z-index: 1;
    text-align: center;

    .section-title {
      font-family: $font-primary;
      font-size: 2rem;
      font-weight: $font-weight-light;
      margin-bottom: 1rem;
      color: $white-color;
      position: relative;
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;

      &::before,
      &::after {
        content: '•';
        color: $primary-color;
        font-size: 1.5rem;
      }
    }

    .section-description {
      font-family: $font-primary;
      font-size: 1rem;
      font-weight: $font-weight-light;
      line-height: 1.5;
      margin-bottom: 1.5rem;
      color: $white-color;
    }
  }
}
